<template>
  <article>
    <h1>솔루션 & 서비스</h1>
    <ul class="gnb">
      <router-link to="/sub2_a" exact-active-class="active" tag="li" class="on">Platform</router-link>
      <router-link to="/sub2_b" exact-active-class="active" tag="li">DashBoard</router-link>
      <router-link to="/sub2_c" exact-active-class="active" tag="li">GIS</router-link>
      <router-link to="/sub2_d" exact-active-class="active" tag="li">Mobile</router-link>
      <router-link to="/sub2_e" exact-active-class="active" tag="li">Open GIS</router-link>
    </ul>
    <section class="sub2">
      <h2>PLATFORM</h2>
      <div class="s_all">
        <div class="s_img">
          <img src="/img/ser_a1.jpg" alt="" class="on">
          <img src="/img/ser_a2.jpg" alt="">
          <img src="/img/ser_a3.jpg" alt="">
          <img src="/img/ser_a4.jpg" alt="">
          <img src="/img/ser_a5.jpg" alt="">
          <img src="/img/ser_a6.jpg" alt="">
        </div>
        <div class="s_txt">
          <h3>스마트시티 에너지플랫폼</h3>
          <div class="s_content1">
            <h4>주요기능</h4>
            <p> - oAuth 2.0 기반의 SSO</p>
            <p>&nbsp;</p>
            <p> - 화면 구성 기능</p>
            <p>&nbsp;</p>
            <p> - 플랫폼연계 및 디자인 가이드</p>
          </div>
          <div class="s_content2">
            <h4>특징</h4>
            <p>- 플랫폼 포털을 쉽게 구성할 수 있습니다.</p>
            <p>&nbsp;</p>
            <p>- 여러가지 MSA 프로젝트 연계가 가능합니다.</p>
          </div>
        </div>
      </div>
      <div class="container">
        <i class="fas fa-caret-left g_left"></i>
        <div class="gallery_box">
          <ul class="gallery">
            <li class="on"><img src="/img/ser_a1.jpg" alt=""></li>
            <li><img src="/img/ser_a2.jpg" alt=""></li>
            <li><img src="/img/ser_a3.jpg" alt=""></li>
            <li><img src="/img/ser_a4.jpg" alt=""></li>
            <li><img src="/img/ser_a5.jpg" alt=""></li>
            <li><img src="/img/ser_a6.jpg" alt=""></li>
          </ul>
        </div>
        <i class="fas fa-caret-right g_right"></i>
      </div>
    </section>
  </article>
</template>
<script>
export default {
  mounted(){
    //슬라이드 이미지 
    $(document).on("click", ".gallery li", function(){
      var a = $(this).index();
      $('.gallery li').removeClass('on')
      $('.gallery li').eq(a).addClass('on')
      $('.s_img img').removeClass('on')
      $('.s_img img').eq(a).addClass('on')
    });
    //왼쪽 아이콘 누를시
    var b=0;
    $(document).on('click','.g_left',function(){
      if(b>=1) b--;
        $('.gallery li').css('left', -11*b+'vw')
    })
    //오른쪽 아이콘 누를시
    $(document).on('click','.g_right',function(){
      if(b<=0) b++;
        $('.gallery li').css('left', -11*b+'vw')
    })
    //gnb 메뉴 누를시 다시 초기화
    $(document).on('click','.gnb li',function(){
      b=0;
    })
  }
}
</script>
